:root {
  --background: #1f1f1f;
  --color: #dddddd;
  --border-color: #dcdcdc;
}

/* General styles */
body, html {
  height: 100%;
  width: 100%;
  background: var(--background);
  color: var(--color);
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, li, a {
  font-family: 'Arvo', serif;
}

h1, h2, h3, h4 {
  word-spacing: 8px;
  letter-spacing: 1px;
}

p, span {
  font-family: 'Jetbrains Mono', monospace;
  line-height: 160%;
}

h2 {
  text-transform: uppercase;
  border-bottom: 2px solid var(--border-color);
  width: auto;
  padding-bottom: 8px;
  display: inline-flex;
}

h3 {
  padding-top: 32px;
}

a {
  color: var(--color);

  &.no-underline {
    text-decoration: none;
  }
}

i.small-link {
  font-size: 70%;
  position: relative;
  top: -4px;
}

section {
  padding: 0 32px;
  width: calc(100% - 64px);
  min-height: calc(100% - 64px);

  h2 {
    padding-top: 64px;
  }
}

section, footer {
  position: relative;
  z-index: 10;
}

section#intro {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  img {
    border-radius: 50%;
    height: 256px;
  }

  #menu {
    padding-top: 16px;

    a {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px white solid;
      padding: 8px;
      margin: 8px;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

section#about {
  ul {
    margin: 0;
    padding: 0;
    column-count: 3;
    column-gap: 64px;
    list-style: none;

    li {
      break-inside: avoid-column;

      h4 {
        margin: 4px 0;
      }

      p {
        margin-top: 0;
        margin-bottom: 24px;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    ul {
      column-count: 3;
    }
  }

  @media only screen and (max-width: 800px) {
    ul {
      column-count: 2;
    }
  }

  @media only screen and (max-width: 600px) {
    ul {
      column-count: 1;
    }
  }
}

section#further {
  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      width: 200px;
    }

    p {
      padding-left: 32px;
    }

    a {
      text-decoration: none;
      padding-right: 16px;
    }
  }
}

footer {
  padding: 16px;
  text-align: center;
}
