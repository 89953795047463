/* JetBrainsMono-Bold - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 700;
  font-family: "JetBrains Mono";
  font-style: normal;
  src: local("JetBrains Mono Bold"), local("JetBrainsMono-Bold"),
    url("./JetBrainsMono-Bold.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-BoldItalic - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 700;
  font-family: "JetBrains Mono";
  font-style: italic;
  src: local("JetBrains Mono Bold Italic"), local("JetBrainsMono-BoldItalic"),
    url("./JetBrainsMono-BoldItalic.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-ExtraBold - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 800;
  font-family: "JetBrains Mono ExtraBold";
  font-style: normal;
  src: local("JetBrains Mono ExtraBold"), local("JetBrainsMono-ExtraBold"),
    url("./JetBrainsMono-ExtraBold.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-ExtraBoldItalic - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 800;
  font-family: "JetBrains Mono ExtraBold";
  font-style: italic;
  src: local("JetBrains Mono ExtraBold Italic"),
    local("JetBrainsMono-ExtraBoldItalic"),
    url("./JetBrainsMono-ExtraBoldItalic.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-ExtraLight - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 200;
  font-family: "JetBrains Mono ExtraLight";
  font-style: normal;
  src: local("JetBrains Mono ExtraLight"), local("JetBrainsMono-ExtraLight"),
    url("./JetBrainsMono-ExtraLight.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-ExtraLightItalic - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 200;
  font-family: "JetBrains Mono ExtraLight";
  font-style: italic;
  src: local("JetBrains Mono ExtraLight Italic"),
    local("JetBrainsMono-ExtraLightItalic"),
    url("./JetBrainsMono-ExtraLightItalic.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-Italic - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 400;
  font-family: "JetBrains Mono";
  font-style: italic;
  src: local("JetBrains Mono Italic"), local("JetBrainsMono-Italic"),
    url("./JetBrainsMono-Italic.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-Light - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 300;
  font-family: "JetBrains Mono Light";
  font-style: normal;
  src: local("JetBrains Mono Light"), local("JetBrainsMono-Light"),
    url("./JetBrainsMono-Light.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-LightItalic - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 300;
  font-family: "JetBrains Mono Light";
  font-style: italic;
  src: local("JetBrains Mono Light Italic"), local("JetBrainsMono-LightItalic"),
    url("./JetBrainsMono-LightItalic.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-Medium - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 500;
  font-family: "JetBrains Mono Medium";
  font-style: normal;
  src: local("JetBrains Mono Medium"), local("JetBrainsMono-Medium"),
    url("./JetBrainsMono-Medium.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-MediumItalic - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 500;
  font-family: "JetBrains Mono Medium";
  font-style: italic;
  src: local("JetBrains Mono Medium Italic"),
    local("JetBrainsMono-MediumItalic"),
    url("./JetBrainsMono-MediumItalic.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-Regular - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 400;
  font-family: "JetBrains Mono";
  font-style: normal;
  src: local("JetBrains Mono Regular"), local("JetBrainsMono-Regular"),
    url("./JetBrainsMono-Regular.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-SemiBold - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 600;
  font-family: "JetBrains Mono SemiBold";
  font-style: normal;
  src: local("JetBrains Mono SemiBold"), local("JetBrainsMono-SemiBold"),
    url("./JetBrainsMono-SemiBold.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-SemiBoldItalic - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 600;
  font-family: "JetBrains Mono SemiBold";
  font-style: italic;
  src: local("JetBrains Mono SemiBold Italic"),
    local("JetBrainsMono-SemiBoldItalic"),
    url("./JetBrainsMono-SemiBoldItalic.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-Thin - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 100;
  font-family: "JetBrains Mono Thin";
  font-style: normal;
  src: local("JetBrains Mono Thin"), local("JetBrainsMono-Thin"),
    url("./JetBrainsMono-Thin.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}

/* JetBrainsMono-ThinItalic - Copyright 2020 The JetBrains Mono Project Authors (https://github.com/JetBrains/JetBrainsMono) */
@font-face {
  font-weight: 100;
  font-family: "JetBrains Mono Thin";
  font-style: italic;
  src: local("JetBrains Mono Thin Italic"), local("JetBrainsMono-ThinItalic"),
    url("./JetBrainsMono-ThinItalic.woff2") format("woff2");
  font-feature-settings: "aalt" 1, "calt" 1, "case" 1, "ccmp" 1, "frac" 1,
    "ordn" 1, "sinf" 1, "subs" 1, "sups" 1, "zero" 1, "mark" 1, "mkmk" 1;
  font-display: swap;
}
